<template>
  <div class="content">
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset :loading="loading" :model.sync="model" @search="updateTableData"></search-reset>
    </l-toggle>
    <dynamic-table
      ref="dynamic-table"
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :pagination.sync="pagination"
      :selections.sync="selections"
      :tools="tools"
      @update="updateTableData"
    ></dynamic-table>
  </div>
</template>

<script>
import LToggle from '../../components/LToggle.vue';
import DynamicForm from '../../components/DynamicForm.vue';
import SearchReset from '../../components/SearchReset.vue';
import DynamicTable from '../../components/DynamicTable.vue';
import baseMixin from '../../mixins/base-mixin';
import api from '@/api/UserListApi';

export default {
  name: 'UserApplList',
  components: {
    LToggle,
    DynamicForm,
    SearchReset,
    DynamicTable,
  },
  mixins: [baseMixin],
  data() {
    return {
      model: {},
      formItems: [
        {
          label: '客户账号',
          type: 'text',
          name: 'phone',
          placeholder: '请输入客户账号',
        },
        {
          label: '客户姓名',
          type: 'text',
          name: 'realName',
          placeholder: '请输入客户姓名',
        },
        {
          label: '客户联系方式',
          type: 'text',
          name: 'phone',
          placeholder: '请输入客户联系方式',
        },
        {
          label: '客户申请时间',
          type: 'daterange',
          starName: 'applyTimeStart',
          starPlaceholder: '请输入开始日期',
          endName: 'applyTimeEnd',
          endPlaceholder: '请输入结束日期',
        },
      ],
      columns: [
        {
          type: 'selection',
          fixed: 'left',
        },
        {
          label: '客户账号',
          prop: 'phone',
        },
        {
          label: '客户姓名',
          prop: 'realName',
        },
        {
          label: '客户联系方式',
          prop: 'phone',
        },
        {
          label: '客户申请时间',
          prop: 'applyTime',
        },
        {
          label: '申请机型',
          prop: 'productName',
        },
        {
          label: '期望寄存价值',
          prop: 'expectConsignPrice',
        },
        {
          label: '地址',
          prop: 'address',
        },
        {
          label: '补充信息',
          prop: 'remark',
        },
      ],
      tableData: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selections: [],
      tools: [],
    };
  },
  computed: {},
  created() {
    this.updateTableData();
  },
  methods: {
    updateTableData() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
        };
        api
          .getUserApplyList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss"></style>
